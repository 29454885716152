import { IEnvironment, IEnvironmentList } from "../models/candidate.models";
import { environment } from 'src/environments/environment';

type Environment = 'DEV' | 'QA' | 'STAGE' | 'PROD';


function isValidEnvironment(value: string | undefined): value is Environment {
  if(value == undefined){
      return false;
  }
  return ['DEV', 'QA', 'STAGE', 'PROD'].includes(value);
} 

const isValid =  isValidEnvironment(environment.ENVIRONMENT)
const activeEnvironment: Environment = isValid ? (environment.ENVIRONMENT as Environment) || 'DEV' : 'DEV';

export const currentCountry = (environment.LOCATION == "ESP") ? 'ESP' : 'MEX'; // MEX o ESP
const rollbarKey = 'c8bc3b7a4cd34ef08afe345e84e5e40c';

const countriesCurrency = {
  MEX: {
    code: 'MEX',
    countryCode: 'MXN',
    currency: 'Peso mexicano',
    icon: '/assets/svg/mex-icon.svg',
    currencyIcon: '/assets/svg/generic-currency.svg',
  },
  ESP: {
    code: 'ESP',
    countryCode: 'EUR',
    currency: 'Euro',
    icon: '/assets/svg/spain-flag-icon.svg',
    currencyIcon: '/assets/svg/euro-icon.svg',
  },
  COL: {
    code: 'COL',
    countryCode: 'COP',
    currency: 'Peso colombiano',
    icon: '/assets/svg/colombia-flag-icon.svg',
    currencyIcon: '/assets/svg/generic-currency.svg',
  },
  USA:{
    code: 'USA',
    countryCode: 'USD',
    currency: 'Dólares',
    icon: '/assets/svg/usa-flag-icon.svg',
    currencyIcon: '/assets/svg/generic-currency.svg',
  },
  PER:{
    code: 'PER',
    countryCode: 'PEN',
    currency: 'Sol',
    icon: '/assets/svg/usa-flag-icon.svg',
    currencyIcon: '/assets/svg/generic-currency.svg',
  },
  CHL:{
    code: 'CHL',
    countryCode: 'CLP',
    currency: 'Peso Chileno',
    icon: '/assets/svg/usa-flag-icon.svg',
    currencyIcon: '/assets/svg/generic-currency.svg',
  },
  ARG:{
    code: 'ARG',
    countryCode: 'ARS',
    currency: 'Peso Argentino',
    icon: '/assets/svg/usa-flag-icon.svg',
    currencyIcon: '/assets/svg/generic-currency.svg',
  }
}

const SETTINGS: GlobalSettings = {
    MEX: {
      keySystemIdentifier: 'México',
      code: 'MEX',
      countryGroup:[{
        codeStripe: 'MXN',
        code: 'MEX',
        name: 'Mexico',
        icon: '/assets/svg/mexico-flag-icon.svg',
        currencyList:[
          countriesCurrency.MEX,
          countriesCurrency.USA,
          countriesCurrency.COL,
          countriesCurrency.ESP
        ],
        lada: '52'
      },
      {
        code: 'ESP',
        codeStripe: 'EUR',
        name: 'España',
        icon: '/assets/svg/spain-flag-icon.svg',
        currencyList:[
          countriesCurrency.ESP,
        ],
        lada: '34'
      },
      {
        code: 'COL',
        codeStripe: 'COP',
        name: 'Colombia',
        icon: '/assets/svg/colombia-flag-icon.svg',
        currencyList:[
          countriesCurrency.USA,
          countriesCurrency.COL,
          countriesCurrency.ESP
        ],
        lada: '57'
      },
      {
        code: 'USD',
        codeStripe: 'USD',
        name: 'Estados Unidos',
        icon: '/assets/svg/usa-flag-icon.svg',
        currencyList:[
          countriesCurrency.USA,
          countriesCurrency.COL,
          countriesCurrency.ESP
        ],
        lada: '57'
      }],
      hasMultipleSalaryPeriodicity: true,
      salaryMinValue: 100,
      momentLocale:"es-mx",
      maxPhoneFieldLength: 12,
      minPhoneFieldLength: 12,
      phoneFormaterPattern: new RegExp(/(\d{0,2})(\d{0,4})(\d{0,4})/),
      phoneValidatorPattern: new RegExp(/\d{2} \d{4} \d{4}/),
      minEducationLevelToChangeEducationStatus: 3,
      educationStatusInLowerEducation: [1, 2, 4],
      educationStatusInHigherEducation: [1, 2, 3, 4, 5],
      inMaintenance: false,
      webPageLink:'https://www.involverh.com/empleo/'
    },
    ESP: {
      keySystemIdentifier: 'España',
      code: 'ESP',
      countryGroup:[{
        codeStripe: 'MXN',
        code: 'MEX',
        name: 'México',
        icon: '/assets/svg/mexico-flag-icon.svg',
        currencyList:[
          countriesCurrency.MEX,
          countriesCurrency.USA,
          countriesCurrency.COL,
          countriesCurrency.ESP
        ],
        lada: '52'
      },
      {
        code: 'ESP',
        codeStripe: 'EUR',
        name: 'España',
        icon: '/assets/svg/spain-flag-icon.svg',
        currencyList:[
          countriesCurrency.ESP,
        ],
        lada: '34'
      },
      {
        code: 'COL',
        codeStripe: 'COP',
        name: 'Colombia',
        icon: '/assets/svg/colombia-flag-icon.svg',
        currencyList:[
          countriesCurrency.USA,
          countriesCurrency.COL,
          countriesCurrency.ESP
        ],
        lada: '57'
      },
      {
        code: 'USD',
        codeStripe: 'USD',
        name: 'Estados Unidos',
        icon: '/assets/svg/usa-flag-icon.svg',
        currencyList:[
          countriesCurrency.USA,
          countriesCurrency.COL,
          countriesCurrency.ESP
        ],
        lada: '57'
      }],
      hasMultipleSalaryPeriodicity: true,
      salaryMinValue: 1,
      momentLocale:"es",
      maxPhoneFieldLength: 12,
      minPhoneFieldLength: 11,
      phoneFormaterPattern: new RegExp(/(\d{0,2})(\d{0,4})(\d{0,4})/),
      phoneValidatorPattern: new RegExp(/\d{2} \d{4} \d{4}/),
      minEducationLevelToChangeEducationStatus: 5,
      educationStatusInLowerEducation: [1, 3],
      educationStatusInHigherEducation: [1, 2, 4],
      inMaintenance: false,
      webPageLink:'https://www.involverh.es/empleo/'
  }
}

const ENVIRONMENTS: IEnvironmentList  = {
  MEX: {
    DEV: {
      production: false,
      currentCountry: currentCountry,
      countrySettings: SETTINGS[currentCountry],
      chatbot: {
        endpoint: 'https://hyeybxnqb0.execute-api.us-east-1.amazonaws.com/dev/chatbot-onboarding',
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMDcwNDMwLjY2OTUzNn0.SUfNvgaBKnM9xMiSxilrhcqAmt3j59PawcjyNtX0fIc',
      },
      apiUrl: {
        vacancy: 'https://dev.micros.involverh.com.mx/vacancy',
        management: 'https://dev.micros.involverh.com.mx/management',
        user: 'https://dev.micros.involverh.com.mx/user',
        auth: 'https://dev.micros.involverh.com.mx/auth',
        psychometric: 'https://dev.micros.involverh.com.mx/psychometric',
        files: 'https://dev.micros.involverh.com.mx/files',
        smartSearch: 'https://searchvacant.dev.ia.involverh.com.mx/search-vacancy',
        parser: 'https://parser.dev.ia.involverh.com.mx'
      },
      links: {
        recruiter: 'https://involvedevreclu.involverh.com.mx',
        candidate: 'https://involvedevcan.involverh.com.mx',
        jobPool: 'https://involvedevbolsa.involverh.com.mx',
        psychometric: 'https://involverh.psicometrica.mx',
      },
      integrationKeys: {
        talkjs: 'tqksAfwX',
        rollbar: {
          key: rollbarKey,
          env: 'dev-mx',
          enabled: false
        }
      },
      legals: {
        privacyUrl: 'https://involverh.com/aviso-de-privacidad',
        termsUrl: 'https://involverh.com/terminos-y-condiciones',
        cookiesUrl: 'https://involverh.com/politica-de-cookies'
      }
    },
    QA: {
      production: false,
      currentCountry: currentCountry,
      countrySettings: SETTINGS[currentCountry],
      chatbot: {
        endpoint: 'https://hyeybxnqb0.execute-api.us-east-1.amazonaws.com/pre/chatbot-onboarding',
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMTQwOTI3LjIyMDI5MTR9.WOBAvdjsk6OSEU8aAaGMUfCfWD59I6lnXzEIyKzqKHE',
      },
      apiUrl: {
        vacancy: 'https://pre.micros.involverh.com.mx/vacancy',
        management: 'https://pre.micros.involverh.com.mx/management',
        user: 'https://pre.micros.involverh.com.mx/user',
        auth: 'https://pre.micros.involverh.com.mx/auth',
        psychometric: 'https://pre.micros.involverh.com.mx/psychometric',
        files: 'https://pre.micros.involverh.com.mx/files',
        smartSearch: 'https://searchvacant.qa.ia.involverh.com.mx/search-vacancy',
        parser: 'https://parser.qa.ia.involverh.com.mx'
      },
      links: {
        recruiter: 'https://involveprereclu.involverh.com.mx',
        candidate: 'https://involveprecan.involverh.com.mx',
        jobPool: 'https://involveprebolsa.involverh.com.mx',
        psychometric: 'https://involverh.psicometrica.mx',
      },
      integrationKeys: {
        talkjs: 'tqksAfwX',
        rollbar: {
          key: rollbarKey,
          env: 'qa-mx',
          enabled: true
        }
      },
      legals: {
        privacyUrl: 'https://involverh.com/aviso-de-privacidad',
        termsUrl: 'https://involverh.com/terminos-y-condiciones',
        cookiesUrl: 'https://involverh.com/politica-de-cookies'
      }
    },
    STAGE: {
      production: false,
      currentCountry: currentCountry,
      countrySettings: SETTINGS[currentCountry],
      chatbot: {
        endpoint: 'https://hyeybxnqb0.execute-api.us-east-1.amazonaws.com/pre/chatbot-onboarding',
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMTQwOTI3LjIyMDI5MTR9.WOBAvdjsk6OSEU8aAaGMUfCfWD59I6lnXzEIyKzqKHE',
      },
      apiUrl: {
        vacancy: 'https://stage.micros.involverh.com.mx/vacancy',
        management: 'https://stage.micros.involverh.com.mx/management',
        user: 'https://stage.micros.involverh.com.mx/user',
        auth: 'https://stage.micros.involverh.com.mx/auth',
        psychometric: 'https://stage.micros.involverh.com.mx/psychometric',
        files: 'https://stage.micros.involverh.com.mx/files',
        smartSearch: 'https://searchvacant.stage.ia.involverh.com.mx/search-vacancy',
        parser: 'https://parser.stage.ia.involverh.com.mx'
      },
      links: {
        recruiter: 'https://involvestagereclu.involverh.com.mx',
        candidate: 'https://involvestagecan.involverh.com.mx',
        jobPool: 'https://involvestagebolsa.involverh.com.mx',
        psychometric: 'https://involverh.psicometrica.mx',
      },
      integrationKeys: {
        talkjs: 'tqksAfwX',
        rollbar: {
          key: rollbarKey,
          env: 'stage-mx',
          enabled: false
        }
      },
      legals: {
        privacyUrl: 'https://involverh.com/aviso-de-privacidad',
        termsUrl: 'https://involverh.com/terminos-y-condiciones',
        cookiesUrl: 'https://involverh.com/politica-de-cookies'
      }
    },
    PROD: {
      production: true,
      currentCountry: currentCountry,
      countrySettings: SETTINGS[currentCountry],
      chatbot: {
        endpoint: 'https://hyeybxnqb0.execute-api.us-east-1.amazonaws.com/pro/chatbot-onboarding',
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMzQ4NDE5LjIyNTMyNDR9.va5WdjJX2jqQGNFu7DEZNOv8ddEfE-N0zBl376ZM8QE',
      },
      apiUrl: {
        vacancy: 'https://prod.micros.involverh.com.mx/vacancy',
        management: 'https://prod.micros.involverh.com.mx/management',
        user: 'https://prod.micros.involverh.com.mx/user',
        auth: 'https://prod.micros.involverh.com.mx/auth',
        psychometric: 'https://prod.micros.involverh.com.mx/psychometric',
        files: 'https://prod.micros.involverh.com.mx/files',
        smartSearch: 'https://searchvacant.prod.ia.involverh.com.mx/search-vacancy',
        parser: 'https://parser.prod.ia.involverh.com.mx'
      },
      links: {
        recruiter: 'https://reclutalent.involverh.com',
        candidate: 'https://talent.involverh.com',
        jobPool: 'https://vacantes.involverh.com',
        psychometric: 'https://involverh.psicometrica.mx',
      },
      integrationKeys: {
        talkjs: 'EgcCQfVU',
        rollbar: {
          key: rollbarKey,
          env: 'prod-mx',
          enabled: true
        }
      },
      legals: {
        privacyUrl: 'https://involverh.com/aviso-de-privacidad',
        termsUrl: 'https://involverh.com/terminos-y-condiciones',
        cookiesUrl: 'https://involverh.com/politica-de-cookies'
      }
    },
  },
  ESP: {
    DEV: {
      production: false,
      currentCountry: currentCountry,
      countrySettings: SETTINGS[currentCountry],
      chatbot: {
        endpoint: 'https://hyeybxnqb0.execute-api.us-east-1.amazonaws.com/dev/chatbot-onboarding',
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMDcwNDMwLjY2OTUzNn0.SUfNvgaBKnM9xMiSxilrhcqAmt3j59PawcjyNtX0fIc',
      },
      apiUrl: {
        vacancy: 'https://cubone.involverh.com/vacancy',
        management: 'https://cubone.involverh.com/management',
        user: 'https://cubone.involverh.com/user',
        auth: 'https://cubone.involverh.com/auth',
        psychometric: 'https://cubone.involverh.com/psychometric',
        files: 'https://cubone.involverh.com/files',
        smartSearch: 'https://searchvacant.dev.ia.involverh.com.mx/search-vacancy',
        parser: 'https://parser.dev.ia.involverh.com.mx'
      },
      links: {
        recruiter: 'https://involvedevreclu.involverh.es',
        candidate: 'https://involvedevcan.involverh.es',
        jobPool: 'https://involvedevbolsa.involverh.es',
        psychometric: 'https://involverh.psicometrica.mx',
      },
      integrationKeys: {
        talkjs: 'tqksAfwX',
        rollbar: {
          key: rollbarKey,
          env: 'dev-es',
          enabled: false
        }
      },
      legals: {
        privacyUrl: 'https://www.involverh.es/aviso-de-privacidad',
        termsUrl: 'https://www.involverh.es/terminos-y-condiciones',
        cookiesUrl: 'https://www.involverh.es/politica-de-cookies'
      }
    },
    QA: {
      production: false,
      currentCountry: currentCountry,
      countrySettings: SETTINGS[currentCountry],
      chatbot: {
        endpoint: 'https://hyeybxnqb0.execute-api.us-east-1.amazonaws.com/pre/chatbot-onboarding',
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMTQwOTI3LjIyMDI5MTR9.WOBAvdjsk6OSEU8aAaGMUfCfWD59I6lnXzEIyKzqKHE',
      },
      apiUrl: {
        vacancy: 'https://kadabra.involverh.com/vacancy',
        management: 'https://kadabra.involverh.com/management',
        user: 'https://kadabra.involverh.com/user',
        auth: 'https://kadabra.involverh.com/auth',
        psychometric: 'https://kadabra.involverh.com/psychometric',
        files: 'https://kadabra.involverh.com/files',
        smartSearch: 'https://searchvacant.qa.ia.involverh.es/search-vacancy',
        parser: 'https://parser.qa.ia.involverh.es'
      },
      links: {
        recruiter: 'https://involveprereclu.involverh.es',
        candidate: 'https://involveprecan.involverh.es',
        jobPool: 'https://involveprebolsa.involverh.es',
        psychometric: 'https://involverh.psicometrica.mx',
      },
      integrationKeys: {
        talkjs: 'tqksAfwX',
        rollbar: {
          key: rollbarKey,
          env: 'qa-es',
          enabled: true
        }
      },
      legals: {
        privacyUrl: 'https://www.involverh.es/aviso-de-privacidad',
        termsUrl: 'https://www.involverh.es/terminos-y-condiciones',
        cookiesUrl: 'https://www.involverh.es/politica-de-cookies'
      }
    },
    STAGE: {
      production: false,
      currentCountry: currentCountry,
      countrySettings: SETTINGS[currentCountry],
      chatbot: {
        endpoint: 'https://hyeybxnqb0.execute-api.us-east-1.amazonaws.com/pre/chatbot-onboarding',
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMTQwOTI3LjIyMDI5MTR9.WOBAvdjsk6OSEU8aAaGMUfCfWD59I6lnXzEIyKzqKHE',
      },
      apiUrl: {
        vacancy: 'https://umbreon.involverh.com/vacancy',
        management: 'https://umbreon.involverh.com/management',
        user: 'https://umbreon.involverh.com/user',
        auth: 'https://umbreon.involverh.com/auth',
        psychometric: 'https://umbreon.involverh.com/psychometric',
        files: 'https://umbreon.involverh.com/files',
        smartSearch: 'https://searchvacant.stage.ia.involverh.es/search-vacancy',
        parser: 'https://parser.stage.ia.involverh.es'
      },
      links: {
        recruiter: 'https://involvestagereclu.involverh.es',
        candidate: 'https://involvestagecan.involverh.es',
        jobPool: 'https://involvestagebolsa.involverh.es',
        psychometric: 'https://involverh.psicometrica.mx',
      },
      integrationKeys: {
        talkjs: 'tqksAfwX',
        rollbar: {
          key: rollbarKey,
          env: 'stage-es',
          enabled: false
        }
      },
      legals: {
        privacyUrl: 'https://www.involverh.es/aviso-de-privacidad',
        termsUrl: 'https://www.involverh.es/terminos-y-condiciones',
        cookiesUrl: 'https://www.involverh.es/politica-de-cookies'
      }
    },
    PROD: {
      production: true,
      currentCountry: currentCountry,
      countrySettings: SETTINGS[currentCountry],
      chatbot: {
        endpoint: 'https://hyeybxnqb0.execute-api.us-east-1.amazonaws.com/pro/chatbot-onboarding',
        token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiSmF2aSIsImFkbWluIjpmYWxzZSwiaWF0IjoxNjYwMzQ4NDE5LjIyNTMyNDR9.va5WdjJX2jqQGNFu7DEZNOv8ddEfE-N0zBl376ZM8QE',
      },
      apiUrl: {
        vacancy: 'https://vulpix.micros.involverh.com/vacancy',
        management: 'https://vulpix.micros.involverh.com/management',
        user: 'https://vulpix.micros.involverh.com/user',
        auth: 'https://vulpix.micros.involverh.com/auth',
        psychometric: 'https://vulpix.micros.involverh.com/psychometric',
        files: 'https://vulpix.micros.involverh.com/files',
        smartSearch: 'https://searchvacant.prod.ia.involverh.es/search-vacancy',
        parser: 'https://parser.prod.ia.involverh.es'
      },
      links: {
        recruiter: 'https://reclutalent.involverh.es',
        candidate: 'https://talent.involverh.es',
        jobPool: 'https://vacantes.involverh.es',
        psychometric: 'https://involverh.psicometrica.mx',
      },
      integrationKeys: {
        talkjs: 'EgcCQfVU',
        rollbar: {
          key: rollbarKey,
          env: 'prod-es',
          enabled: true
        }
      },
      legals: {
        privacyUrl: 'https://www.involverh.es/aviso-de-privacidad',
        termsUrl: 'https://www.involverh.es/terminos-y-condiciones',
        cookiesUrl: 'https://www.involverh.es/politica-de-cookies'
      }
    },
  },
};
export const appSettings: IEnvironment = ENVIRONMENTS[currentCountry][activeEnvironment]  || ENVIRONMENTS.MEX.DEV;
export const KEYSYSTEM_SETTINGS = SETTINGS[currentCountry] || SETTINGS["MEX"];
export const CURRENT_COUNTRY = KEYSYSTEM_SETTINGS.countryGroup.find((country: any)=>{
  return country.code = currentCountry
})

interface GlobalSettings {
  [key: string]: KeySystemSettings
}

export interface KeySystemSettings {
  keySystemIdentifier: string;
  code: string;
  hasMultipleSalaryPeriodicity: boolean;
  salaryMinValue: number;
  momentLocale: string;
  maxPhoneFieldLength: number,
  phoneFormaterPattern: RegExp,
  phoneValidatorPattern: RegExp,

  minEducationLevelToChangeEducationStatus: number;

  // LowerEducationLevel: From 1 to (minEducationLevelToShowAdditionalFields - 1)
  // A list of education status available based on selected education level
  educationStatusInLowerEducation: number[];
    
  // HigherEducationLevel: From minEducationLevelToShowAdditionalFields
  // A list of education status available based on selected education level
  educationStatusInHigherEducation: number[];

  //                             Trunco  En curso    Pasante Egresado    Titulado
  // Edu básica                  x       x                   x
  // Bachilletato                x       x                   x
  // Bachillerato técnico        x       x           x       x           x
  // TSU                         x       x           x       x           x
  // Licenciatura                x       x           x       x           x
  // Especialidad                x       x           x       x           x
  // Maestría                    x       x           x       x           x
  
  
  
  //                             Sin finalizar   En curso    Graduado    Titulado
  // Educación infantil          x                           x
  // Educación primaria          x                           x
  // ESO                         x                           x
  // Bachillerato                x                           x
  // FP                          x               x                       x
  // Carrera universitaria       x               x                       x
  // Másteres y posgrados        x               x                       x
  // Doctorado                   x               x                       x
  inMaintenance: boolean;

  webPageLink: string;

  countryGroup: any;

  minPhoneFieldLength: any;
}
