import { Component } from '@angular/core';
import { ToastService } from './services/toast/toast.service';
import { Router } from '@angular/router';
import { LoaderService } from './services/loader/loader.service';
import { TranslateService } from  '@ngx-translate/core';
import { appSettings } from './globals/appSettings';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'InvolveRHCandidato';
  banderita = true;
  loaderActive = false;
  constructor(
    private router: Router,
    private loaderService:LoaderService,
    translate:  TranslateService
    ){
    this.loaderService.loaderStatus.subscribe((status) => {
      setTimeout(() => {
        this.loaderActive = status;
      }, 10);
    })
    if (!appSettings.production){
      window.console.log = () => {}
    }
    
  }

  onLogout(): void{
    localStorage.clear();
    this.router.navigateByUrl('/');
  }

}
