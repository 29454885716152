import { Component, EventEmitter, Input, Output } from '@angular/core';
import { appSettings } from 'src/app/globals/appSettings';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss']
})
export class EmailSentComponent {
  @Output() mainActionClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() hidesMainActionButtonAfterClicked: boolean = true;
  @Input() isMainActionButtonShown = true;
  

  onMainActionClicked(): void{
    this.mainActionClicked.emit();
    if (this.hidesMainActionButtonAfterClicked){
      // ! In order to avoid infinite number of requests
      this.isMainActionButtonShown = false;
    }
  }
}
