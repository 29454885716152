import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { appSettings } from 'src/app/globals/appSettings';
import { ICandidate, IVacancy } from 'src/app/models/candidate.models';
import { CustomCurrencyPipe } from 'src/app/pipes/custom.currencypipe';
import { ApiService } from 'src/app/services/api/api.service';
import { formatAreas, formatAsFirstCapital, formatClientEmployees, formatFunctions, formatLanguages, formatPublicationDate, formatSpecialties } from 'src/app/services/custom-formatters/custom-formatters.service';
import { DeviceService } from 'src/app/services/device/device.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-vacancy-detail-page',
  templateUrl: './vacancy-detail-page.component.html',
  styleUrls: ['./vacancy-detail-page.component.scss']
})
export class VacancyDetailPageComponent implements OnInit {
  @Input() currentVacancy?: IVacancy;
  @Input() displayingFromChatWindow = false;
  hidesVacancyInfo = false;
  previousUrl?: string;
  fullPostulationsList: any[] = [];
  selectionProcessId = '';
  showHeader = false;
  currentPeriodicitySalary = '';
  currentCandidate: any;
  moment = moment;
  favorites = []
  isFavorite!: boolean;
  @ViewChild('postulationButton') 
  postulationButton : ElementRef | undefined;
  constructor(private apiService: ApiService,
              private activatedRoute: ActivatedRoute,
              private toastService: ToastService,
              private router: Router,
              private storageService: StorageService,
              private translateService: TranslateService,
              private deviceId: DeviceService,
              private customCurrencyPipe: CustomCurrencyPipe,
              private route: ActivatedRoute){ }

  ngOnInit(): void {
    const navigationState = this.router.getCurrentNavigation()?.extras.state;

    if(this.storageService.get('candidate') && this.storageService.get('token')){
      this.showHeader = true;
      this.apiService.getCandidate().subscribe({
        next: (getCandidateResponse: ICandidate) => {
          this.currentCandidate = getCandidateResponse;
        }
      });
      this.getFavorites(this.currentVacancy?.vacantId || '')
    }

    if (navigationState) {
      // ! From search, favorites and postulations, a Vacancy can be directly passed
      // ! to avoid request *again* the same vacancy model.
      // ! As well, it's passed the url where the user came from
      // ! in order to get it back if click the back button
      this.currentVacancy = navigationState['vacancy'];
     
      this.currentPeriodicitySalary = 'por '+ this.currentVacancy?.periodicitySalary?.name.toLowerCase();
      this.previousUrl = navigationState['previousUrl'];
      this.isCandidateAlreadyPostulated();
    }
    else if (this.currentVacancy !== undefined){
      // ! this.currentVacancy comes from postulation-chat component
      // TODO: Add a proper validation to this flow
    }
    else{
      // ! If a user arrives directly from a shared url, gets the vacancy information
      // ! from a get request
      const vacancyId = this.activatedRoute.snapshot.paramMap.get('vacancyId');
      if (vacancyId){
        if (this.router.url.includes('postulation')){
          this.onPostulate(vacancyId);
        }
        else{
          this.apiService.getVacancy(vacancyId).subscribe({
            next: (getVacancyResponse: HttpResponse<IVacancy>) => {
              this.currentVacancy = getVacancyResponse.body as IVacancy;
              this.getFavorites(this.currentVacancy?.vacantId)
              this.currentPeriodicitySalary = 'por '+ this.currentVacancy?.periodicitySalary?.name.toLowerCase();
              if (this.currentVacancy.status !== 'ACTIVA'){
                this.hidesVacancyInfo = true;
              }
              else{
                this.isCandidateAlreadyPostulated();
              }
            },
            error: (getVacancyError: HttpErrorResponse) => {
              if (getVacancyError.status === 404){
                this.hidesVacancyInfo = true;
              }
              else{
                this.toastService.error(this.translateService.instant('errors.generic.default'), getVacancyError.message);
              }
            }
          });
        }
      }
      else{
        // ! If there isn't a vacancyId, redirects the user to the dashboard
        // this.router.navigateByUrl('/dashboard');
        this.toastService.error('Ocurrió un error', '');
      }

    }
  }

  ngAfterViewInit() {
    const vacancyId = this.activatedRoute.snapshot.paramMap.get('apply');
    if (vacancyId && vacancyId === 'automatic-apply') {
      setTimeout(() => {
        let el: HTMLElement = this.postulationButton?.nativeElement as HTMLElement;
        el.click();
      }, 1000);
    }
  }


  getFavorites(vacantId: String){ 
    this.apiService.getFavorites().subscribe({
      next: (getFavoritesResponse: any) => {
        this.favorites = getFavoritesResponse.body.content
        const findFavorite = this.favorites.filter((favorite: any)=>{
          return favorite.vacant.vacantId == vacantId
        });
        this.isFavorite = findFavorite.length > 0;      
      }
    });
  }

  get isSalaryExactly(): boolean{
    return this.currentVacancy?.salaryExactly !== null;
  }

  isCandidateAuthenticated(): boolean{
    return this.storageService.isCandidateAuthenticated();
  }

  formatClientEmployees(clientEmployees: string): string{
    return formatClientEmployees(clientEmployees);
  }

  formatPublicationDate(publicationDate: string): string{
    const browserLang = this.translateService.getBrowserLang() ?? '';
    return formatPublicationDate(publicationDate, browserLang );
  }

  formatFuncions(functions: string): string[]{
    return formatFunctions(functions);
  }

  formatAreas(vacancyAreas: any[]): string{
    return formatAreas(vacancyAreas);
  }

  formatSpecialties(vacancySpecialties: any[]): string{
    return formatSpecialties(vacancySpecialties);
  }

  formatLanguages(vacancyLanguages: any[]): string{
    return formatLanguages(vacancyLanguages)
  }
  
  formatAsFirstCapital(originalValue: string): string{
    return formatAsFirstCapital(originalValue)
  }

  isCandidateAlreadyPostulated(): void{
    if (this.currentCandidate){
      const params = {
        candidateId: this.currentCandidate?.candidateId || '', 
        vacantId: this.currentVacancy?.vacantId,
      };
      this.apiService.isCandidateAlreadyPostulated(params).subscribe({
        next: (isCandidateAlreadyPostulatedResponse: HttpResponse<any>) => {
          this.selectionProcessId = isCandidateAlreadyPostulatedResponse.body;
        },
        error: (isCandidateAlreadyPostulatedError: HttpErrorResponse) => {}
      });
    }
  }

  onPostulate(pendingVacancyId?: string): void{
    if (this.isCandidateAuthenticated()){
      const params = {
        vacantId: this.currentVacancy?.vacantId || pendingVacancyId
      };
      this.apiService.postulateCandidate(params).subscribe({
        next: (postulateCandidateResponse: HttpResponse<any>) => {
          if (postulateCandidateResponse.status === 200){
            this.router.navigateByUrl(`/dashboard/postulation/${ postulateCandidateResponse.body }`);
          }
          else{
            this.toastService.error(this.translateService.instant('errors.generic.default'), postulateCandidateResponse.body);
          }
        },
        error: (postulateCandidateError: HttpErrorResponse) => {
          if (postulateCandidateError.status === 412){
            if (postulateCandidateError.error === 'Para poder postularte debes completar tu CV.'){
              this.toastService.error(this.translateService.instant('errors.vacancyDetail.completeProfile'), '');
              this.router.navigateByUrl('/dashboard/my-cv');
            }
            else{
              this.toastService.error(this.translateService.instant('errors.vacancyDetail.already'), '');
              if (this.router.url.includes('postulation')){
                this.router.navigateByUrl('/dashboard/vacancies/postulations')
              }
            }
          }
          else if (postulateCandidateError.status === 423){
            this.toastService.error(this.translateService.instant('errors.vacancyDetail.notAvailable'), this.translateService.instant('errors.vacancyDetail.ideal'))
            this.router.navigateByUrl('/dashboard/vacancies/search');
          }
          else{
            this.toastService.error(this.translateService.instant('errors.generic.default'), postulateCandidateError.message);
            this.router.navigateByUrl('/dashboard/vacancies/search');
          }
        }
      });
    }
    else{
      this.storageService.set('origin-vacancy',this.currentVacancy?.vacantId || pendingVacancyId || null);
      this.toastService.success(this.translateService.instant('vacancyDetail.signUp'), '');
      this.router.navigateByUrl('/sign-up');
    }
  }

  onToggleFavorite(vacancy: any): void{
    const params = {
      vacantId: vacancy.vacantId
    };
    this.apiService.toggleFavorite(params).subscribe({
      next: (toggleFavorite: HttpResponse<string>) => {
        this.isFavorite = !this.isFavorite;
        if(this.isFavorite){
          this.toastService.success('','Se agregó a tu lista de favoritos');
        }
        this.getFavorites(vacancy.vacantId);
      },
      error: (toggleFavoriteError: HttpErrorResponse) => {
        this.toastService.error(this.translateService.instant('errors.generic.default'), toggleFavoriteError.message);
      }
    });
  }

  onShareVacancyLink(vacancy: any): void{
    console.log(vacancy);
    const url = `${ appSettings.links.candidate }/vacancy/${ vacancy.vacantId }`;
    if (navigator.share){
      navigator.share({
        url,
        text: '¡Me gustaría compartir contigo esta vacante!',
        title: vacancy.name,
      });
    }
    else{
      this.deviceId.copyToClipboard(url);
      this.toastService.success(this.translateService.instant('search.copy'), url)
    }
  }

  transformSalary(value: string | number = 0, currency:any){
    return this.customCurrencyPipe.transform(value, {country:currency.codeStripe, enableCode: true});
  }

}
function ngAfterViewInit() {
  throw new Error('Function not implemented.');
}

