import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from "rxjs";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { appSettings } from "src/app/globals/appSettings";
import { StorageService } from "../storage/storage.service";
import { MicroServices } from "src/app/models/candidate.enums";
import { ICandidate, IVacancy } from 'src/app/models/candidate.models';
import { Router } from '@angular/router';
import { ToastService } from '../toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient, 
                private storageService: StorageService,
                private toastService: ToastService,
                private router: Router,
                private translateService: TranslateService) { }

    /**
     * 
     * @param microServiceInstance 
     * @param method 
     * @param endpoint 
     * @param data 
     * @param options 
     * @param isPsychometric 
     * @returns 
     */
    private callService( microServiceInstance: MicroServices,
        method: string = 'POST',
        endpoint: string,
        data?: any,
        options?: any,
        ): Observable<any> {
    
      var methods:any = {
        'GET': function (http: HttpClient, getApiUrl:any) {
            return http.get(`${ getApiUrl(microServiceInstance) }${ endpoint }`, options || {});
        },
        'POST': function (http: HttpClient, getApiUrl:any) {
          return http.post(`${ getApiUrl(microServiceInstance) }${ endpoint }`, data, options || {});
        },
        'PATCH': function (http: HttpClient, getApiUrl:any, mapObjectToPatch:any) {
            return http.patch(`${ getApiUrl(microServiceInstance) }${ endpoint }`, mapObjectToPatch(data), options || {});
        },
        'PUT': function (http: HttpClient, getApiUrl:any) {
            return http.put(`${ getApiUrl(microServiceInstance) }${ endpoint }`, data, options || {});
        },
        'DELETE': function (http: HttpClient, getApiUrl:any) {
          if(data){
            options.body = data;
          } 
          return http.delete(`${ getApiUrl(microServiceInstance) }${endpoint}`, options || {});
        }
    };
    
    return methods[method](this.http, this.getApiUrl, this.mapObjectToPatch)
  }
  
  /**
  * 
  * @param microserviceInstance 
  * @returns 
  */
  private getApiUrl(microserviceInstance: string): string {
    switch (microserviceInstance) {
      case 'vacancy':
      return appSettings.apiUrl.vacancy;
      case 'management':
      return appSettings.apiUrl.management;
      case 'user':
      return appSettings.apiUrl.user;
      case 'auth':
        return appSettings.apiUrl.auth;
      case 'psychometric':
      return appSettings.apiUrl.psychometric;
      case 'files':
      return appSettings.apiUrl.files;
      case 'files':
        return appSettings.apiUrl.files;
      case 'smartSearch':
        return appSettings.apiUrl.smartSearch
      case 'parser':
        return appSettings.apiUrl.parser
      default:
      return '';
    }
  }
  
  /**
  * @author Javier Olvera
  * @param originalObject 
  * @returns 
  */
  private mapObjectToPatch(originalObject: any): any {
    const patchObjectList: any[] = [];
    Object.keys(originalObject).forEach(property => {
      patchObjectList.push({
        op: 'replace',
        path: `/${property}`,
        value: originalObject[property]
      });
    });
    return patchObjectList;
  }
  
  /**
   * Sends an email to set/reset the user's password
   * #SignUp, #ResetPassword, #ForgotPassword
   * @author Javier Olvera
   * @param email 
   * @returns A message with "ok"
   */
  recoverPassword(email: string): Observable<any> {
    return this.callService(MicroServices.AUTH, 'POST', `/petition-restore-pass`, null, { observe: 'response', responseType:'text', params: {
      email
    } });
  }
  
  /**
  * #Onboarding, #ResetPassword, #SignInUp
  * @returns 
  */
  getCandidate(refresh = false): Observable<ICandidate> {
    if (refresh) {
      this.storageService.remove('candidate')
    }
    const candidate = this.storageService.get('candidate');
    
    if (candidate) {
      return of(JSON.parse(candidate));
    }else{
      return this.callService(MicroServices.USER, 'GET', `/candidate`, null, { observe: 'response' })
        .pipe(
          tap((response: HttpResponse<ICandidate>) => {
            if (response.body === null){
              localStorage.clear();
              this.router.navigateByUrl('/');
              this.toastService.error(this.translateService.instant('errors.generic.default'), this.translateService.instant('errors.generic.please'));
            }
            else{

            }
            this.storageService.set('candidate', JSON.stringify(response.body))
          }),
          map((response: HttpResponse<ICandidate>) => {
            return response.body || JSON.parse(this.storageService.get('candidate') || '{}');
          })
        );
    }
  }


    /**
  * #Onboarding, #ResetPassword, #SignInUp
  * @returns 
  */
    getCandidateNoLogOut(refresh = false): Observable<ICandidate> {
      return this.callService(MicroServices.USER, 'GET', `/candidate`, null, { observe: 'response' }) 
    }
  
  /**
   * Logs in a user
   * #Login
   * @author Javier Olvera
   * @param body { email: string, password: string } 
   * @returns A Candidate
  */
  loginCandidate(body: any): Observable<any> {
    return this.callService(MicroServices.AUTH, 'POST', `/login`, body, { observe: 'response' });
  }
  
  /**
   * Checks if an email is registered in our DB
   * #Login
   * @author Javier Olvera
   * @param email 
   * @returns 
   */
  validateEmailExists(body: any): Observable<any> {
    return this.callService(MicroServices.AUTH, 'POST', `/login`, body, { observe: 'response', responseType: 'text' });
  }
  
  /**
   * Signs up a user
   * #SignUp
   * @author Javier Olvera
   * @param body { email: string, keySystem: 'MEX' | 'ESP', userRol: 'CANDIDATE' }
   * @returns A Candidate 
   */
  signUpUser(body: any): Observable<any> {
    return this.callService(MicroServices.AUTH, 'POST', `/registry/candidate`, body, { observe: 'response', responseType: 'text' });
  }

  /**
   * Signs up a user
   * #SignUp
   * @author Lucio Perez
   * @param body { "name": "string", "lastName": "string", "dateBirth": "2024-03-26T18:19:54.399Z", "areaId": "string", "cityId": "string"}
   * @returns A Candidate 
   */
  completeUser(body: any): Observable<any> {
    return this.callService(MicroServices.AUTH, 'POST', `/registry/candidate/complete`, body, { observe: 'response' });
  }

  /**
   * Gets a new token from on an expired one
   * @author Javier Olvera
   * @param params { token: string, email: string }
   * @returns A Candidate
   */
  refreshToken(params: any): Observable<any>{
    return this.callService(MicroServices.AUTH, 'GET', '/refresh-token', null, { observe: 'response', params });
  }
  
  /**
  * #AccountStatusHandler
  * @param token 
  * @returns 
  */
  verifyEmailCandidate(token: string): Observable<any> {
    return this.callService(MicroServices.USER, 'GET', `/registry/session-aux/email?token=${token}`, null);
  }
  
  /**
   * Sets a password for new users
   * #Password
   * @author Javier Olvera
   * @param params { password: string } 
   * @returns 
   */
  createPassword(params: any ): Observable<any> {
    return this.callService(MicroServices.AUTH, 'PUT', `/create-pass`, null, { observe: 'response', params });
  }

  /**
   * Sets a password for new users
   * #Password
   * @author Javier Olvera
   * @param params { password: string } 
   * @returns 
   */
  changePassword(params: any ): Observable<any> {
    return this.callService(MicroServices.AUTH, 'PUT', `/change-password`, null, { observe: 'response', params });
  }

  /**
   * #Onboarding
   * @param newsletter 
   * @param privacity 
   * @param terms 
   * @param cookies 
   * @returns 
   */
  acceptConditions(body:any): Observable<any>{ 
    return this.callService(MicroServices.USER, 'PUT', `/registry/accept-conditions?cookies=${body.cookies}&newsletter=${body.newsletter}&privacity=${body.privacity}&terms=${body.terms}`, null, {observe: 'response'});
  }
  
  /**
  * #ResetPassword, #ForgotPassword
  * @param password 
  * @returns 
  */
  resetPassword(params: any): Observable<any> {
    return this.callService(MicroServices.AUTH, 'POST', `/confirm-restore-pass`, null, {observe: 'response', params});
  }

  /**
   * #Onboarding
   * @param phone 
   * @returns 
   */
  sendSms(params: any): Observable<any>{
    return this.callService(MicroServices.AUTH, 'POST', `/send-sms`, null, {observe: 'response', params});
  }

  /**
   * #Onboarding
   * @param phone 
   * @returns 
   */
  changePhone(params: any): Observable<any>{
    return this.callService(MicroServices.AUTH, 'POST', `/change-phone`, null, {observe: 'response', params});
  }
  
  /**
  * #Onboarding
  * @param code 
  * @returns 
  */
  checkCodeSms(params: any): Observable<any> {
    return this.callService(MicroServices.AUTH, 'POST', `/verify-code-sms`, null, {observe: 'response', responseType: 'text', params});
  }

    /**
   * #Onboarding
   * @param email 
   * @returns 
   */
    changeEmail(params: any): Observable<any>{
      return this.callService(MicroServices.AUTH, 'POST', `/change-email`, null, {observe: 'response', params});
    }

  /**
   * #Onboarding
   * @param email 
   * @returns 
   */
  verifyEmail(params: any): Observable<any>{
    return this.callService(MicroServices.AUTH, 'POST', `/verify-change-email`, null, {observe: 'response', params});
  }
  
  /**
  * #Onboarding
  * @returns 
  */
  checkEmail(): Observable<any> {
    return this.callService(MicroServices.AUTH, 'PUT', `/find-email`, null, {observe: 'response', responseType: 'text'});
  }
  
  /**
  * #Onboarding
  * @param body 
  * @returns 
  */
  patchCandidate(body: any): Observable<any> {
    return this.callService(MicroServices.USER, 'PATCH', `/candidate`, body, {observe: 'response'});
  }

  /**
   * #Onboarding
   * @param catalogName 
   * @returns 
   */
  getCatalogByKeySystem(catalogName: string): Observable<any>{
    return this.callService(MicroServices.MANAGEMENT, 'GET', `/catalog-system/by-type/all?type=${ catalogName }`, null, {observe: 'response'});
    // TODO: Cachear respuesta
  }

  /**
   * #Onboarding
   * @returns 
   */
  nationalityAll(): Observable<any>{
    return this.callService(MicroServices.MANAGEMENT, 'GET', `/catalog/nationality`, null, {observe: 'response'} );
  }

  /**
   * #Onboarding
   * @param query 
   * @returns 
   */
  getCatalogPosition(query:string): Observable<any> {
    return this.callService(MicroServices.MANAGEMENT, 'GET', `/catalog/position?query=${query}`, null, {observe: 'response'});
  }
  
  /**
  * #Onboarding, #Vacancy
  * @param idPais 
  * @returns 
  */
  consultState(idPais: string): Observable<any> {
    return this.callService(MicroServices.MANAGEMENT, 'GET', `/catalog/state?countryCode=${idPais}`, null, {observe: 'response'});
  }
  
  /**
  * #Onboarding, #Vacancy
  * @param idPais 
  * @param idEstado 
  * @returns 
  */
  consultMunicipio(idPais: string, idEstado: string): Observable<any> {
    return this.callService(MicroServices.MANAGEMENT, 'GET', `/catalog/city?countryCode=${idPais}&stateCode=${idEstado}`, null, {observe: 'response'});
  }
  
  /**
  * #Onboarding, #Vacancy
  * @returns 
  */
  getCountry(): Observable<any> {
    return this.callService(MicroServices.MANAGEMENT, 'GET', `/catalog/country`, null, {observe: 'response'});
  }
  
  /**
  * #Onboarding
  * @param file 
  * @returns 
  */
  uploadCv(file: any): Observable<any> {
    return this.callService(MicroServices.FILES, 'POST', `/extraccioncv/upload-files?typeFile=pdf`, file);
  }
  
  /**
  * #Onboarding
  * @param file 
  * @returns 
  */
  uploadInvolveCv(file: any): Observable<any> {
    // TODO: Reemplazar implementación por uploadProfilePhoto
    return this.http.post(`${this.getApiUrl(MicroServices.FILES)}/upload/upload-candidate-cv?md5Hash=123`, file, { observe: 'response', responseType:'text'});
  }

    /**
  * #Onboarding
  * @param file 
  * @returns 
  */
  uploadFile(file: any): Observable<any> {
    // TODO: Reemplazar implementación por uploadProfilePhoto
    return this.http.post(`${this.getApiUrl(MicroServices.FILES)}/upload/uploadFile?typeFile=URL_FILE`, file, { observe: 'response', responseType:'text'});
  }

  /**
   * Uploads a CV, a book, or a profile picture
   * @param file a png image
   * @param params `{ typeFile: 'URL_CV' | 'URL_FILE' | 'URL_PHOTO' }`
   * @returns An ok message
   */
  uploadProfilePhoto(file: FormData, params: any): Observable<any>{
    // TODO: Cambiar nombre por uploadFile
    return this.callService(MicroServices.FILES, 'POST', '/upload/uploadFile', file, { observe: 'response', params, responseType:'text' });
  }

  deleteFiles(typeFile: string) {
    return this.callService(MicroServices.FILES, 'DELETE', `/upload/delete-file?typeFile=${typeFile}`, null, { observe: 'response', responseType:'text'});
  }
    
  
  /**
  * #Onboarding
  * @returns 
  */
  getCVProcessInvolve(): Observable<any> {
    return this.callService(MicroServices.FILES, 'GET', `/extraccioncv/result-extraccioncv`);
  }
  
  /**
   * #Onboarding, #My-cv
   * @param body 
   * @param section 
   * @param id 
   * @returns 
   */
  setExtractionCv(body: any, section:string, id: string): Observable<any> {
    const path = section === 'experience'?'/experience?experienceTTId':'/education?educationTTId';
    return this.callService(MicroServices.FILES, 'PATCH', `/extraccioncv${path}=${id}`, body);
  }
  
  /**
  * #Onboarding, #My-cv
  * @returns 
  */
  getPositionTypes(): Observable<any> | undefined {
    return this.callService(MicroServices.MANAGEMENT, 'GET', '/catalog/type-position', null )?.pipe(
      map((positionTypes: any[]) => {
        return positionTypes.sort((a, b) => (a.name > b.name ? 1 : -1));
      })
    );
  }

  /**
   * #Onboarding, #My-cv
   * @param workExperience 
   * @returns 
   */
  addNewWorkExperience(workExperience: any): Observable<any> {
    return this.callService(MicroServices.USER, 'POST', `/candidate/work-experience`, workExperience, { responseType: 'text'});
  }

  /**
 * #Onboarding, #My-cv
 * @param workExperience 
 * @returns 
 */
  editWorkExperience(workExperience: any): Observable<any> {
    return this.callService(MicroServices.USER, 'PUT', `/candidate/work-experience`, workExperience, { responseType: 'text'});
  }

  /**
   * 
   * @param workExperienceId 
   * @returns 
   */
  deleteWorkExperience(workExperienceId: string): Observable<any>{
    return this.callService(MicroServices.USER, 'DELETE', `/candidate/work-experience?experienceId=${workExperienceId}`, null, { responseType: 'text'});
  }

  /**
   * 
   * @param educationId 
   * @returns 
   */
  deleteEducation(educationId: string): Observable<any>{
    return this.callService(MicroServices.USER, 'DELETE', `/candidate/education?educationId=${educationId}`, null, { responseType: 'text'});
  }

    /**
   * #Onboarding, #My-cv
   * @param education 
   * @returns 
   */
    addNewEducation(education: any): Observable<any> {
      return this.callService(MicroServices.USER, 'POST', `/candidate/education`, education, { responseType: 'text'});
    }
  
    /**
   * #Onboarding, #My-cv
   * @param education 
   * @returns 
   */
    editEducation(education: any): Observable<any> {
      return this.callService(MicroServices.USER, 'PUT', `/candidate/education`, education, { responseType: 'text'});
    }

  /**
   * #onboarding
   * @param phone 
   * @returns 
   */
  resendPhone( phone: any): Observable<any>{
    return this.callService(MicroServices.AUTH, 'POST', `/resend-sms?phone=${phone}`, null, { observe: 'response', responseType: 'text'});
  }
  
  /** Get a list of vacancies marked as favorite
   * #Vacancy
   * @author Javier Olvera
   * @returns A list of favorite-vacancies, in a sorteable object
   */
  getFavorites(): Observable<any>{
    const params = {
      page: 0,
      size: 50
    };
    // TODO: Create params object from component
    return this.callService(MicroServices.USER, 'GET', `/candidate/vacants/favorite`, null, { observe: 'response', params });
  }

  /**
   * `Do not use`
   * Get a list of vacancies based on candidate model criteria
   * #vacancy
   * @deprecated at v2.1.2+
   * @author Javier Olvera
   * @returns A list of vacancies, in a sorteable object
   */
  searchVacancies(): Observable<any>{
    const params = {
      page: 0,
      size: 25,
    };
    return this.callService(MicroServices.VACANCY, 'GET', `/search-vacant/recommended`, null, { observe: 'response', params });
  }

  /**
   * 
   * @param postulationsActive 
   * @returns 
   */
  getPostulations(postulationsActive: boolean, refresh: boolean): Observable<any>{
    const params = {
      page: 0,
      size: 30,
      status: postulationsActive,
      sortBy: 'postulationDate',
      sortDirection: 'ASC',
      orderBy: 'postulationDate',
    };

    return this.callService(MicroServices.USER, 'GET', `/candidate/postulation`, null, { observe: 'response', params })

  }

  /**
   * Attempts to create a postulation
   * @author Javier Olvera
   * @param params { vacantId: string }
   * @returns 
   */
  postulateCandidate(params: any): Observable<any>{
    return this.callService(MicroServices.USER, 'POST', '/candidate/postulation', null, { observe: 'response', params, responseType: 'text' });
  }

  /**
   * Gets a postulation from an Id
   * @author Javier Olvera
   * @param postulationId 
   * @returns A Postulation
   */
  getPostulation(postulationId: string): Observable<any>{
    return this.callService(MicroServices.USER, 'GET', `/selection-process/${ postulationId }`, null, { observe: 'response' });
  }

  /**
   * Set a postulation's status as "FUERA_HISTORIAL" and isActive as false.
   * @author Javier Olvera
   * @param params { selectionProcessId: string }
   * @returns 
   */
  deletePostulation(params: any): Observable<any>{
    return this.callService(MicroServices.USER, 'DELETE', '/candidate/postulation', null, { observe: 'response', responseType: 'text', params });
  }

  /**
   * Toggles as favorite a vacancy
   * @author Javier Olvera
   * @param params { vacantId: string } to mark/unmark as favorite
   * @returns A text with "ok"
   */
  toggleFavorite(params: any): Observable<any>{
    return this.callService(MicroServices.USER, 'POST', '/candidate/vacants/favorite', null, { observe: 'response', responseType: 'text', params });
  }

  /**
   * Get the full info of a vacancy
   * @author Javier Olvera
   * @param vacancyId a VacancyId
   * @returns A Vacancy
   */
  getVacancy(vacancyId: string): Observable<any>{
    return this.callService(MicroServices.VACANCY, 'GET', `/search-vacant/${ vacancyId }`, null, { observe: 'response' });
  }

  /**
   * 
   * @param catalog 
   * @returns 
   */
  getCatalog(catalog: string): Observable<any>{
    return this.callService(MicroServices.MANAGEMENT, 'GET',`/catalog${catalog}`, null, { observe: 'response' });
  }

  /**
 * 
 * @param catalog 
 * @returns 
 */
  getEnum(enumKey: string): Observable<any>{
    return this.callService(MicroServices.MANAGEMENT, 'GET',`/catalog-system/enum?typeEnum=${enumKey}`, null, { observe: 'response' });
  }
  
  /**
   * Requested when filtering vacancies
   * #Vacancy
   * @returns A list of contract types
   */
  getContractTypes(): Observable<any>{
    const params = {
      code: this.storageService.getCurrentKeySystem()
    };
    return this.callService(MicroServices.MANAGEMENT, 'GET', '/catalog/type-contract', null, { observe: 'response', params });
    // TODO: Cachear respuesta
  }

  /**
   * Gets a vacancy's set of questions to be asked to the candidate 
   * #Postulation
   * @author Javier Olvera
   * @param params { page: number, size: number, processId: string, questionnaire: 'AREA_SPECIALTY' | 'SOFT_SKILL' | 'HARD_SKILL' }
   * @returns A list of questions
   */
  getVacancyQuestions(params: any): Observable<any>{
    return this.callService(MicroServices.USER, 'GET', '/candidate/postulation/question', null, { observe: 'response', params })
  }

  /**
   * Saves a postulations answers 
   * @author Javier Olvera
   * @param params { processId: string, questionnaire: 'EXPERIENCE' | 'HARD_SKILL' | 'SOFT_SKILL' }
   * @param body { CandidateAnswer[] }
   * @returns A message with "ok"
   */
  saveVacancyQuestions(params: any, body: any[]): Observable<any>{
    return this.callService(MicroServices.USER, 'POST', '/candidate/postulation/answer', body, { observe: 'response', responseType: 'text', params })
  }

  /**
   * Gets the presentation questions catalog
   * #Postulation
   * @author Javier Olvera
   * @returns A list of questions 
   */
  getPresentationQuestions(): Observable<any>{
    return this.callService(MicroServices.MANAGEMENT, 'GET', '/catalog/question-presentation', null, { observe: 'response' })
  }

  /**
   * Saves the presentation video recorded by the candidate
   * #Postulation
   * @author Javier Olvera
   * @param body presentation video inside a formData
   * @returns presentation path
   */
  savePresentationVideo(body: FormData): Observable<any>{
    return this.callService(MicroServices.FILES, 'POST', '/upload/presentation-video', body, { observe: 'response' });
  }

  /**
   * Saves the interview video recorded by the candidate
   * #Postulation
   * @author Javier Olvera
   * @param body postulation video inside a formData
   * @param params selection process id
   * @returns interview path
   */
  saveInterviewVideo(body: any, params: any): Observable<any>{
    return this.callService(MicroServices.FILES, 'POST', '/upload/interview-video', body, { observe: 'response', params });
  }

  savePresentationQuestions(body: any[]): Observable<any>{
    return this.callService(MicroServices.USER, 'POST', '/candidate/questions-presentation', body, { observe: 'response', responseType: 'text' });
  }

  getInterviewQuestions(params: any): Observable<any>{
    return this.callService(MicroServices.USER, 'GET', '/candidate/postulation/question', null, { observe: 'response', params });
  }

  /**
   * #My-cv
   * @author Lucio Perez
   * @param params 
   * @returns 
   */
  addAreaSpeciality(params: any): Observable<any>{
    return this.callService(MicroServices.USER, 'POST', '/candidate/area' ,params, {responseType: 'text'});
  }

  /**
   * #My-cv
   * @author Lucio Perez
   * @param params 
   * @returns 
   */
    deleteSpecialityId(params: any) {
      return this.callService(MicroServices.USER, 'DELETE', `/candidate/speciality`, null, { responseType: 'text', params });
    }

  /**
   * #My-cv
   * @author Lucio Perez
   * @param query 
   * @returns 
   */
  getCatalogHardSkill(query:string): Observable<any> {
    return this.callService(MicroServices.MANAGEMENT, 'GET', `/catalog/hard-skill?query=${query}`, null, {observe: 'response'});
  }
  
  /**
   * #My-cv
   * @author Lucio Perez
   * @param params 
   * @returns 
   */
  addHardSkill(params: any): Observable<any>{
    return this.callService(MicroServices.USER, 'POST', '/candidate/hard-skill' ,params, {responseType: 'text'});
  }

  /**
   * #My-cv
   * @author Lucio Perez
   * @param params 
   * @returns 
   */
  deleteHardSkill(params: any): Observable<any>{
    return this.callService(MicroServices.USER, 'DELETE', `/candidate/hard-skill`, null, { responseType: 'text', params});
  }

  /**
   * #Postulation
   * @author Lucio Perez
   * @param postulation 
   * @returns 
   */
  sendPsicometrics(params: any) {
    return this.callService(MicroServices.VACANCY, 'POST', '/psychometric/assignment', null, { observe: 'response', params});
  }

  /**
   * 
   * @param params 
   * @returns 
   */
  getPsychometricsBySelectionProcessId(params: any): Observable<any>{
    return this.callService(MicroServices.VACANCY, 'GET', '/psychometric/assignment', null, { observe: 'response', params })
  }

  /**
   * #My-cv
   * @author Lucio Perez
   * @param query 
   * @returns 
   */
  getCatalogSoftSkill(query:string): Observable<any> {
    return this.callService(MicroServices.MANAGEMENT, 'GET', `/catalog/soft-skill?query=${query}`, null, {observe: 'response'});
  }

  /**
   * #My-cv
   * @author Lucio Perez
   * @param params 
   * @returns 
   */
    addSoftSkill(params: any): Observable<any> {
      return this.callService(MicroServices.USER, 'POST', '/candidate/soft-skill' ,params, {responseType: 'text'});
    }

  /**
   * #My-cv
   * @author Lucio Perez
   * @param params 
   * @returns 
   */
    deleteSoftSkill(params: any): Observable<any> {
      return this.callService(MicroServices.USER, 'DELETE', `/candidate/soft-skill`, null, { responseType: 'text', params});
    }

  /**
   * #My-cv
   * @author Lucio Perez 
   * @param params 
   * @returns 
   */
    addCourse(params: any): Observable<any>{
      return this.callService(MicroServices.USER, 'PUT', '/candidate/course' ,params, { observe: 'response', responseType: 'text' });
    }

  /**
   * #My-cv
   * @author Lucio Perez
   * @param params 
   * @returns 
   */
     deleteCourse(params: any): Observable<any>{
      return this.callService(MicroServices.USER, 'DELETE', `/candidate/course`, null, { responseType: 'text', params});
    }

    /**
     * #My-cv
     * @author Lucio Perez
     * @param body 
     * @returns 
     */
    putCertification(body:any){
      return this.callService(MicroServices.USER, 'PUT', `/candidate/certification`, body, { responseType: 'text', observe: 'response' });
    }

    /**
     * #My-cv
     * @author Lucio Perez 
     * @param params 
     * @returns 
     */
    deleteCertification(params: any) {
      return this.callService(MicroServices.USER, 'DELETE', `/candidate/certification`, null, { responseType: 'text', params});
    }

    /**
     * #My-cv
     * @author Lucio Perez
     * @param params 
     * @returns 
     */
    addLanguage(params:any){
      return this.callService(MicroServices.USER, 'POST', '/candidate/language' ,params, { observe: 'response', responseType: 'text' });
    }

    /**
     * #My-cv
     * @author Lucio Perez
     * @param params 
     * @returns 
     */
    deleteLanguage(params: any){
      return this.callService(MicroServices.USER, 'DELETE', `/candidate/language`, null, { responseType: 'text', params});
    }

    verifyPassword( params: any ){
      return this.callService(MicroServices.AUTH, 'POST', `/verify-password`, null, { responseType: 'response', params});
    }

    deleteCandidate(params: any){
      return this.callService(MicroServices.MANAGEMENT, 'DELETE', `/user/delete-account`, null, { responseType: 'response', params});
    }
  
    /**
     * Search for vacancies by exact criteria
     * @author Javier Olvera
     * @param params `{ offset: number, limit: number }` Pagination options
     * @param body optional parameters to filter vacancies
     * @returns A list of vacancies
     */
    searchVacanciesWithAi(params: any, body: any): Observable<any>{
      return this.callService(MicroServices.SMART_SEARCH, 'GET', '/search-vacancies/', null, { observe: 'response', params: { ...params, ...body } });
    }

    /**
     * Gets a psychometric process from a selection process
     * @param selectionProcessId 
     * @returns A psychometric process
     */
    getPsychometricProcess(selectionProcessId: string): Observable<any>{
      return this.callService(MicroServices.PSYCHOMETRIC, 'GET', `/proccess/${ selectionProcessId }`, null, { observe: 'response' });
    }

    /**
     * 
     * @param body 
     * @returns 
     */
    updateProfile(body: any): Observable<any>{
      return this.callService(MicroServices.USER, 'PUT', '/candidate/about-me', body, { observe: 'response', responseType: 'text' });
    }


      /**
   * 
   * @param body 
   * @returns 
   */
    updatePostulationSalary(body: any): Observable<any>{
      return this.callService(MicroServices.USER, 'POST', '/candidate/postulation/salary-expectation', body, { observe: 'response', responseType: 'text' });
    }

    /**
     * 
     * @param body 
     * @returns 
     */
    addProfileSearch(body: any): Observable<any>{
      return this.callService(MicroServices.USER, 'POST', '/candidate/profile-search', body, { observe: 'response', responseType: 'text' });
    }

     /**
     * 
     * @param body 
     * @returns 
     */
     deleteProfileSearch(profileSearchId: string): Observable<any>{
      return this.callService(MicroServices.USER, 'DELETE', `/candidate/profile-search?profileSearchId=${profileSearchId}`, null, { observe: 'response', responseType: 'text' });
    }

    /**
     * 
     * @param  
     * @returns 
     */
    getProfileSearch(): Observable<any>{
      return this.callService(MicroServices.USER, 'GET', '/candidate/profile-search', null, { observe: 'response' });
    }

  /**
   * Checks if a candidate is already postulated to a vacancy
   * @param params { candidateId: string, vacantId: string }
   * @returns a selectionProcessId or 404 error
   */
  isCandidateAlreadyPostulated(params: any): Observable<any>{
    return this.callService(MicroServices.USER, 'GET', '/candidate/postulation/postulation-exists', null, { observe: 'response', params, responseType: 'text' });
  }

    /**
   * Checks if a candidate is already postulated to a vacancy
   * @param params { candidateId: string, vacantId: string }
   * @returns a selectionProcessId or 404 error
   */
    iaCvParcer(params: any): Observable<any>{
      return this.callService(MicroServices.PARSER, 'POST', '/api/v1/parser/', params, { observe: 'response' });
    }


    acceptPermission(params: any): Observable<any>{
      return this.callService(MicroServices.USER, 'POST', '/permissions/register-list', params, { observe: 'response' });
    }

    registerPermissionSelectionProcess(params: any, selectionProcessId: string): Observable<any>{
      return this.callService(MicroServices.USER, 'POST', `/permissions/selection-process?selectionProcessId=${selectionProcessId}`, params, { observe: 'response' });
    }

    getUserPermission(): Observable<any>{
      return this.callService(MicroServices.USER, 'GET', `/permissions/last-all`, null, { observe: 'response' });
    }

    registerConditionsDetails(params: any): Observable<any>{
      return this.callService(MicroServices.USER, 'POST', `/candidate/postulation/contract-conditions`, params, { observe: 'response' });
    }

    assignCandidatePsychometrics(): Observable<any>{
      return this.callService(MicroServices.VACANCY, 'POST', `/psychometric/assignment-by-candidate`, null, { observe: 'response' });
    }

    getPsychometricsByCandidate(): Observable<any>{
      return this.callService(MicroServices.VACANCY, 'GET', `/psychometric/assignment/by-candidate`, null, { observe: 'response' });
    }

    assignSProccessPsychometrics(selectionProcessId: string): Observable<any>{
      return this.callService(MicroServices.VACANCY, 'POST', `/psychometric/assignment-by-selection-process?selectionProcessId=${selectionProcessId}`, null, { observe: 'response' });
    }

    getPsychometricsBySProccess(selectionProcessId: string): Observable<any>{
      return this.callService(MicroServices.VACANCY, 'GET', `/psychometric/assignment/by-selection-process?selectionProcessId=${selectionProcessId}`, null, { observe: 'response' });
    }

    getCatalogoCurrency(){
      return this.callService(MicroServices.MANAGEMENT, 'GET', `/catalog/currency`, null, { observe: 'response' });
    }
}
