import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Sentry from "@sentry/angular-ivy";

Sentry.init({
  dsn: "https://857ee263364bd341788fd27a91d9301c@o4506633098493952.ingest.sentry.io/4506633100263424",
  tracesSampleRate: 1.0,
  environment: 'production-com',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost","talent.involverh.com","talent.involverh.es"],
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
