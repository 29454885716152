<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="994e5f03-55d2-42e1-be38-6766f73e5cf3" data-blockingmode="auto" type="text/javascript"></script>

<div class="d-flex flex-column flex-md-row main-container">
    <ng-container *ngIf="!emailSentInfo; else emailSentContent" >
        <div class=" w-100 w-md-70 d-flex flex-column position-relative justify-content-center create-account-container p-4"  *ngIf="!isSigningUp">
            <div class="login-img-container">
                <img  src='/assets/svg/login.svg'  alt="Involve iniciar sesión" class="img-fluid grafic-image w-100">
            </div>
        </div>
        <div class=" w-100 w-md-70 p-1 p-md-4 d-flex flex-column position-relative justify-content-center create-account-container" *ngIf="isSigningUp"> 
            <div class="know-more-container">
                <p (click)="goToTalent()">
                    <img  src='/assets/svg/back-button.svg'  alt="" class="img-fluid back-img">
                    <span>
                        {{ 'onboarding.signInUpPage.knowMore1' | translate }} <b>{{ 'onboarding.signInUpPage.knowMore2' | translate }}</b> 
                    </span>
                </p>
            </div>
            <div *ngIf="!isOriginVacancy" class="video-container mx-5 ">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/bicU8gIutdo?si=TN68dI_NJl3Q256C" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div *ngIf="isOriginVacancy" class="vacant-origin desktop d-flex flex-column mx-5 ">
                <div class="d-flex flex-row justify-content-center">
                    <img class="img-fluid pr-2 step-image" src="/assets/images/origin-vacancy-1.png" alt="">
                    <img class="img-fluid pr-2 step-image" src="/assets/images/origin-vacancy-2.png" alt="">
                </div>
                <div class="d-flex flex-row  justify-content-center">
                    <img class="img-fluid pl-2 step-image" src="/assets/images/origin-vacancy-3.png" alt="">
                    <img class="img-fluid pl-2 step-image" src="/assets/images/origin-vacancy-4.png" alt="">
                </div>
            </div>

            <!-- img  src='/assets/svg/candidato_video.svg'  alt="Involve iniciar sesión" class="img-fluid grafic-image w-100" -->
        </div>
        <div class=" w-100 w-md-30 d-flex flex-column justify-content-center px-3 px-md-5 py-0 py-md-5 mt-5 mx-auto login-container" >
            <form class="form-container w-100 flex-grow-1 d-flex flex-column involve-ui mt-5" [formGroup]="signInUpForm">
            <div class="form-group-container">
                <div class="d-flex mt-3 mt-md-5 group-container">
                    <img src="/assets/images/logo-involve.png" alt="Logo Involve RH" class="logo-involve mb-5 mx-auto ">
                </div>
                <ng-container *ngIf="!isSigningUp; else signUpContent">
                    <h2 class="mb-5 mt-1 w-100 text-center title">{{ 'onboarding.signInUpPage.signInTitle' | translate }}</h2>
                    <h5 class="mt-5 w-100 text-center conversation"> {{ 'onboarding.signInUpPage.signInSubtitle' | translate }}</h5>
                </ng-container>
                <ng-template  #signUpContent>
                    <span *ngIf="isOriginVacancy" class="mb-2 mt-1 w-100 origin-vacancy-title">{{ 'onboarding.signInUpPage.originVacant.signUpTitle' | translate }}</span>
                    <h2  *ngIf="!isOriginVacancy" class="mb-2 mt-1 w-100 create-account-title">{{ 'onboarding.signInUpPage.signUpTitle' | translate }}</h2>
                    <p *ngIf="!isSigningUp;"><span class="create-account-match">{{ 'onboarding.signInUpPage.match' | translate }}</span><span class=" create-account-conversation">{{ 'onboarding.signInUpPage.signUpSubTitle' | translate }}</span></p>
                    <div  *ngIf="!isOriginVacancy" class="d-flex flex-column create-account-steps">
                        <span>{{ 'onboarding.signInUpPage.stepsTitle' | translate }}</span>
                        <span>{{ 'onboarding.signInUpPage.step1' | translate }}</span>
                        <span>{{ 'onboarding.signInUpPage.step2' | translate }}</span>
                        <span>{{ 'onboarding.signInUpPage.step3' | translate }}</span>
                        <span>{{ 'onboarding.signInUpPage.step4' | translate }}</span>
                    </div>

                    <div *ngIf="isOriginVacancy" class="vacant-origin mobile d-flex flex-column mx-5 my-5">
                        <div class="d-flex flex-row">
                            <img class="img-fluid pr-2 step-image" src="/assets/images/origin-vacancy-1.png" alt="">
                            <img class="img-fluid pr-2 step-image" src="/assets/images/origin-vacancy-2.png" alt="">
                        </div>
                        <div class="d-flex flex-row">
                            <img class="img-fluid pl-2 step-image" src="/assets/images/origin-vacancy-3.png" alt="">
                            <img class="img-fluid pl-2 step-image" src="/assets/images/origin-vacancy-4.png" alt="">
                        </div>
                    </div>

                    <div *ngIf="isOriginVacancy" class="d-flex flex-column create-account-steps">
                        <div class="d-flex flex-row row-conatainer">
                            <span class="step-number">1</span>
                            <span class="step-text" [innerHTML]="'onboarding.signInUpPage.originVacant.step1' | translate"></span>
                        </div>
                        <div class="d-flex flex-row  row-conatainer">
                            <span  class="step-number">2</span>
                            <span class="step-text" [innerHTML]="'onboarding.signInUpPage.originVacant.step2' | translate"></span>
                        </div>
                        <div class="d-flex flex-row  row-conatainer">
                            <span class="step-number">3</span>
                            <span class="step-text" [innerHTML]="'onboarding.signInUpPage.originVacant.step3' | translate"></span>
                        </div>
                        <div class="d-flex flex-row row-conatainer">
                            <span class="step-number">4</span>
                            <span class="step-text" [innerHTML]="'onboarding.signInUpPage.originVacant.step4' | translate"></span>
                        </div>
                        
                    </div>
                    
                    <div class="d-flex flex-column mb-4 terms-container mb-4" *ngIf="isSigningUp"> 
                        <div class="custom-control custom-switch mt-3">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="conditions">
                            <label class="custom-control-label text ml-2 " for="customSwitch1">He leído y acepto las&nbsp;</label> <a [attr.href]="getCountryTermsLink()" target="_blank">condiciones del servicio</a>.
                        </div>
                        <small class="form-text color-E600 check-error" *ngIf="hasFormControlError('conditions', 'required')">No puedes continuar sin aceptar los terminos.</small>
                        <div class="custom-control custom-switch  mt-2">
                            <input type="checkbox" class="custom-control-input" id="customSwitch2" formControlName="terms">
                            <label class="custom-control-label text ml-2 " for="customSwitch2">He leído y comprendo la&nbsp;</label> <a [attr.href]="getCountryPrivacyLink()" target="_blank">información básica de privacidad</a>.
                        </div>
                        <small class="form-text color-E600 check-error" *ngIf="hasFormControlError('terms', 'required')">No puedes continuar sin aceptar la información básica de privacidad.</small>
                    </div>
                </ng-template>
                <ng-container *ngIf="!isSigningUp && isEmailRegistered">
                    <p class="background-P100 px-3 p-3 border-radius w-100 mb-5">
                        <span class="d-block">{{ 'onboarding.signInUpPage.correctMail' | translate }}</span>
                        <span class="align-middle">{{ emailControl.value }}</span> 
                        <button class="btn btn-link color-S500 pl-0" (click)="onChangeEmail()">{{ 'onboarding.signInUpPage.changeMail' | translate }}</button>
                    </p>
                </ng-container>
                    <ng-container *ngIf="(!isSigningUp && !isEmailRegistered) || isSigningUp">
                        <div class="form-group mb-4" >
                            <div class="input-container">
                                <label class="label">{{ 'onboarding.signInUpPage.emailInput' | translate }}</label>
                                <input class="input" type="text" [placeholder]="'generic.userMail' | translate"
                                formControlName="email"
                                [ngClass]="{ 'is-invalid': hasFormControlError('email') || errorMessage }">
                                <small class="form-text color-E600" *ngIf="hasFormControlError('email', 'required')">{{ 'onboarding.signInUpPage.inputError.emailRequired' | translate }}</small>
                                <small class="form-text color-E600" *ngIf="hasFormControlError('email', 'email')">{{ 'onboarding.signInUpPage.inputError.emailFormat' | translate }}</small>
                            </div>
                        </div>
                        <div class="form-group mb-0 mt-5 mb-5" *ngIf="!isSigningUp && !isEmailRegistered">
                            <div class="input-container">
                                <label class="label">{{ 'onboarding.signInUpPage.passwordInput' | translate }}</label>
                                <input class="input" 
                                type="password"
                                formControlName="password"
                                [ngClass]="{ 'is-invalid': hasFormControlError('password') || errorMessage }" appTogglePasswordType>
                                <small class="form-text color-E600" *ngIf="hasFormControlError('password', 'required')">{{ 'onboarding.signInUpPage.inputError.passwordRequired' | translate }}</small>
                            </div>
                            <div class="d-flex justify-content-center my-3"> 
                                <a *ngIf="!isSigningUp" class="btn btn-link btn-block mt-0 w-100" routerLink="/forgot-password">{{ 'onboarding.signInUpPage.forgetBtn' | translate }}</a>
                            </div>
                        </div>
                    </ng-container>
                    <p class="color-E600 mb-4 text-center" *ngIf="errorMessage">{{ errorMessage }}</p>
                    <div *ngIf="isSigningUp" class="involve-ui mb-4 d-flex flex-column">
                        <div class="input-container d-flex flex-row">
                            <span class="w-50 mr-2 mt-2 font-weight-bold">Selecciona tu nacionalidad</span>
                            <select class="form-control w-50" formControlName="country">
                                <option value="">{{ 'cv.generic.select' | translate }}</option>
                                <option [value]="country.nationalityId" *ngFor="let country of countriesList" (click)="selectCountry(country)">{{ country.name }}</option>
                            </select>
                            <!--div class="custom-dropdown w-50">
                                <span class="selected-option" (click)="toggleDropdown()">
                                    <span class="ml-1">{{ selectedCountry }}</span>
                                </span>
                                <ul class="dropdown-list" *ngIf="showDropdown">
                                    <li *ngFor="let country of countriesList" (click)="selectCountry(country)">
                                        {{ country.name }}
                                    </li>
                                </ul>
                                <small class="form-text color-E600" *ngIf="hasFormControlError('country', 'required')">{{ 'onboarding.signInUpPage.inputError.countryRequired' | translate }}</small>
                            </div-->
                        </div>
                        <div class="mt-2">
                            <small >*Si tienes más de una nacionalidad elige el país donde resides actualmente.</small>
                        </div>
                    </div>
                    <div class="d-flex flex-column submit-buttons mb-5"> 
                        <button class="btn btn-primary btn-block mb-5 align-self-center" 
                        *ngIf="!isSigningUp && !isEmailRegistered"
                        (click)="onValidateEmailExists()" [ngClass]="{ 'disabled': signInUpForm.invalid }">{{ 'onboarding.signInUpPage.signInBtn' | translate }}</button>
                        <button class="btn btn-primary btn-block mb-5 align-self-center" 
                        [ngClass]="{ 'disabled': !signInUpForm.valid }" *ngIf="isSigningUp"
                        (click)="onSignUpAttempt()">{{ 'onboarding.signInUpPage.signUpBtn' | translate }}</button>

                        <span *ngIf="isSigningUp" class="align-self-center mb-4">¿Ya tienes una cuenta? <a routerLink="/sign-in" [replaceUrl]="true">Inicia sesión</a></span>
                        <span *ngIf="!isSigningUp" class="align-self-center mb-4 font-weight-bold ">¿Aún no tienes una cuenta? Regístrate aquí</span>
                        <button *ngIf="!isSigningUp && !isEmailRegistered" class="btn btn-primary mb-3 mb-3 align-self-center" routerLink="/sign-up" [replaceUrl]="true">{{ 'onboarding.signInUpPage.signUpBtn' | translate }}</button>
                    </div>
            </div>
        </form>
        </div>
    </ng-container>
    <ng-template #emailSentContent>
        <app-email-sent (mainActionClicked)="onResendSignUpEmail()">
            <ng-container id="title">{{ emailSentInfo?.title }}</ng-container>
            <ng-container id="detailedMessage">{{ emailSentInfo?.detailedMessage }}</ng-container>
        </app-email-sent>
        <div class="token">
            <p *ngIf="currentToken" >
                {{this.currentToken}}
            </p>
        </div>
    </ng-template>
</div>
<div class="w-100 footer">
    <img class="w-100 desktop-img" src="../../../../assets/svg/curvas.svg" alt="Curvas">
    <img class="w-100 mobile-img" src="../../../../assets/svg/curvas_mobile.svg" alt="Curvas">
    <div class="footer-content w-100">
        <span>
            {{ 'onboarding.legals.knowOur' | translate}}
            <a [attr.href]="getCountryPrivacyLink()" target="_blank" >{{ 'onboarding.legals.privacy' | translate }}</a>, 
            <a [attr.href]="getCountryTermsLink()" target="_blank" >{{ 'onboarding.legals.terms' | translate }}</a> {{'onboarding.legals.prepositionY' | translate}}
            <a [attr.href]="getCountryCookiesLink()" target="_blank" >{{ 'onboarding.legals.cookies' | translate }}</a>
        </span>
    </div>
</div>

