import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICandidate } from 'src/app/models/candidate.models';
import { ApiService } from 'src/app/services/api/api.service';

import { email, hasFormControlError, required} from '../../services/custom-validators/custom-validators.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import packageInfo  from './../../../../package.json'
import { appSettings, currentCountry } from 'src/app/globals/appSettings';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { TranslateService } from '@ngx-translate/core';
import { RollbarService } from 'src/app/handlers/rollback.handler';
import Rollbar from 'rollbar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app.signInUpPage.authenticationFail-in-up-page',
  templateUrl: './sign-in-up-page.component.html',
  styleUrls: ['./sign-in-up-page.component.scss']
})
export class SignInUpPageComponent {
  currentToken?: string;
  isSigningUp = false;
  appVersion = packageInfo.version;
  isAppProductive = appSettings.production;
  isEmailRegistered = false;
  signInUpForm: FormGroup;
  recruiterUrl = appSettings.links.recruiter;
  errorMessage = '';
  emailSentInfo: any;
  acceptConditions = false;
  safeSrc: SafeResourceUrl;
  token = '';
  countriesList: any;
  isOriginVacancy = false;

  selectedCountry: string = 'Seleccionar';
  selectedCountryObj: any;
  showDropdown: boolean = false;
  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private router: Router,
    private apiService: ApiService,
    private toastService: ToastService,
    private onboardingService: OnboardingService,
    private translateService: TranslateService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private sanitizer: DomSanitizer
  ) {
    this.isSigningUp = this.router.url.includes('/sign-up');
    if(this.isSigningUp){
      this.signInUpForm = this.fb.group({
        email: this.fb.control('', [required, email]),
        terms: this.fb.control(false, [Validators.requiredTrue]),
        conditions: this.fb.control(false, [Validators.requiredTrue]),
        country: this.fb.control('', [Validators.required]),

      });
    }else{
      this.signInUpForm = this.fb.group({
        email: this.fb.control('', [required, email]),
        password: this.fb.control('', [required]),
      });
    }
    
    this.signInUpForm.valueChanges.subscribe((change) => {
      this.errorMessage = '';
      this.signInUpForm.markAsUntouched();
    });

    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/c9F5kMUfFKk");
   }

  ngOnInit(): void {
    const tokenSession = this.storageService.get('token');
    const candidate = this.storageService.get('candidate');
    if(tokenSession && candidate){
      this.router.navigateByUrl( this.onboardingService.pathRedirection(JSON.parse(candidate)));
    }else{
      const pendingPostulation = this.storageService.get('origin-vacancy');
      if(pendingPostulation){
        this.isOriginVacancy = true; 
      }
      // ! If user comes from "bolsa", attempted to postulate but got redirected to sign up first.
      // ! So, we save locally the vacancy it wanted to postulate in
      this.storageService.clear()
      this.storageService.set('origin-vacancy', pendingPostulation);
    }
    this.getCountries()
    
  }

  getCountries(): void{
    this.apiService.nationalityAll().subscribe({
      next:(consultCountryuResponse: HttpResponse<any>) => {
        this.countriesList = consultCountryuResponse.body;
      },
      error:(consultCountryuError: HttpErrorResponse) => {
        this.toastService.error('', this.translateService.instant('errors.profile.getContries'));
      }
    });
  }

  

  /**
   * Triggered when the users tries to log in with email
   */
  onValidateEmailExists(): void{
    this.signInUpForm.markAllAsTouched();
    this.errorMessage = '';
    if (this.signInUpForm.valid){
      this.onLoginAttempt()
    }
  }

  /**
   * Triggered when the user tries to log in with email and password
   */
  onLoginAttempt(): void{
    this.signInUpForm.markAllAsTouched();
    const originVacancy = this.storageService.get('origin-vacancy')
    localStorage.clear();
    this.storageService.set('origin-vacancy', originVacancy)
    if (this.signInUpForm.valid){
      const body = {
        email: this.emailControl.value.trim(),
        password: this.passwordControl.value.trim()
      }
      this.apiService.loginCandidate(body).subscribe({
        next: (loginCandidateResponse: HttpResponse<ICandidate>) => {
          if (loginCandidateResponse.body !== null){
            const tokenSession = loginCandidateResponse.headers.get('token');
            const {userId, email} = loginCandidateResponse.body.user;
            this.rollbar.configure({ payload: {
              user_id: userId || 'unknown',
              email: email || 'unknown',
              token: tokenSession ?? 'unknown'
            },})
            if (tokenSession){
              this.storageService.set('token', tokenSession.replace('Bearer ', ''));
              this.storageService.set('candidate', JSON.stringify(loginCandidateResponse.body));
              this.router.navigateByUrl( this.onboardingService.pathRedirection(loginCandidateResponse.body));
            }
            else{
              this.toastService.error(this.translateService.instant('errors.signInUpPage.loginError'), '');
            }
          }
          else{
            this.toastService.error(this.translateService.instant('errors.generic.default'), loginCandidateResponse.statusText);
          }
        },
        error: (loginCandidateError: HttpErrorResponse) => {
          if (loginCandidateError.status === 401){
            this.errorMessage = this.translateService.instant('errors.generic.incorrectPassword');
          }
          else if(loginCandidateError.status === 412){
            // #RN: After three failed login attempts, account get blocked
            // ! Users is blocked by 3+ failed login attempts
            // ! Automatically sends an email with instructions to set a new password
            this.emailSentInfo = {
              detailedMessage: this.translateService.instant('onboarding.signInUpPage.attemptsLimit'),
            }
          }
          else if (loginCandidateError.status === 423){
            this.toastService.error(this.translateService.instant('errors.generic.default'), loginCandidateError.error.message);
            this.apiService.recoverPassword(this.emailControl.value.trim()).subscribe({
              next: (recoverPasswordResponse: HttpResponse<string>) => {
                this.emailSentInfo = {
                  detailedMessage: this.translateService.instant('onboarding.signInUpPage.attemptsLimit'),
                  title: this.translateService.instant('emailSent.titleRecover')
                }
              },
              error: (recoverPasswordError: HttpErrorResponse) => {
                this.toastService.error(this.translateService.instant('errors.generic.default'), recoverPasswordError.message)
              },
            })
          }
          // TODO: Add case for accounts without password
          else{
            this.toastService.error(this.translateService.instant('errors.generic.default'), loginCandidateError.error.message);
          }
        }
      });
    }
  }

  selectCountry(country:any) {
    this.selectedCountry = country.name;
    this.selectedCountryObj = country;
    this.signInUpForm.get('country')?.setValue(country.nationalityId);
    this.showDropdown = false;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }


  /**
   * Triggered when the user returns a step to set its password
   */
  onChangeEmail(): void{
    this.isEmailRegistered = false;
    this.errorMessage = '';
    this.emailControl.markAsUntouched();
  }

  /**
   * Triggered when the user tries to sign up in Involve
   */
  onSignUpAttempt(): void{
    this.signInUpForm.markAllAsTouched();
    this.errorMessage = '';
    if (this.signInUpForm.valid){
      const body: any = {
        email: this.emailControl.value.trim(),
        keySystem: currentCountry,
        acceptPrivacy: this.privacityControl.value,
        acceptTerms: this.conditionsControl.value,
        nationalityId: this.countryControl.value.trim()
      };
      const originVacancy = this.storageService.get('origin-vacancy');
      if(originVacancy){
        body.vacancyOrigin = originVacancy
      }
      this.apiService.signUpUser(body).subscribe({
        next: (signUpUserResponse: HttpResponse<any>) => {
          this.token = signUpUserResponse.headers.get('Token')?.replace('Bearer','') || '';
          if (!appSettings.production){
            this.currentToken =  this.token;
          }
          const { userId, email } = JSON.parse(signUpUserResponse.body || '{}').user;
          this.rollbar.configure({ payload: {
            user_id: userId || 'unknown',
            email: email || 'unknown',
            token:  this.token ?? 'unknown'
          },})
          this.storageService.set('token', this.token.trim());
          this.emailSentInfo = {
            detailedMessage: this.translateService.instant('onboarding.signInUpPage.activeAccount'),
            title: this.translateService.instant('emailSent.title')
          }
        },
        error: (signUpUserError: HttpErrorResponse) => {
          if (signUpUserError.status === 409){
            this.errorMessage = this.translateService.instant('errors.signInUpPage.accountExists');
          }
          else{
            this.toastService.error(this.translateService.instant('errors.generic.default'), signUpUserError.message);
          }
        },
      });
    }
  }

  /**
   * Triggered when the user requests to send again the activation email
   */
  onResendSignUpEmail(): void{
    this.apiService.recoverPassword(this.emailControl.value.trim()).subscribe({
      next: (recoverPasswordResponse: HttpResponse<string>) => {
        this.toastService.success(this.translateService.instant('errors.generic.emailResend'), '');
      },
      error: (recoverPasswordError: HttpErrorResponse) => {
        this.toastService.error(this.translateService.instant('errors.generic.default'), recoverPasswordError.message)
      },
    })
  }



  goToTalent(){
    window.location.href = appSettings.countrySettings.webPageLink;
  }

  hasFormControlError(formControlName: string, errorName?: string): boolean | ValidationErrors{
    return hasFormControlError(this.signInUpForm, formControlName, errorName, true);
  }

  get conditionsControl(): FormControl{
    return this.signInUpForm.get('conditions') as FormControl;
  }

  get privacityControl(): FormControl{
    return this.signInUpForm.get('terms') as FormControl;
  }


  get emailControl(): FormControl{
    return this.signInUpForm.get('email') as FormControl;
  }

  get passwordControl(): FormControl{
    return this.signInUpForm.get('password') as FormControl;
  }

  get countryControl(): FormControl{
    return this.signInUpForm.get('country') as FormControl;
  }

  getCountryPrivacyLink(): string {
    return appSettings.legals.privacyUrl;
  }

  getCountryTermsLink(): string {
    return appSettings.legals.termsUrl;
  }

  getCountryCookiesLink(): string {
    return appSettings.legals.cookiesUrl;
  }
}